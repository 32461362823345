<template>
    <KCourse loader-class="MBcont" course-id="9" title="Эмоции" :items="items">
        <div class="MBtextcont">           
            <p>Поделись улыбкою своей, и она к тебе не раз еще вернется! Помните эту чудесную песенку из детского мультфильма, где открытым текстом дана простая формула счастья 😊 улыбнись миру и он тебе ответит тем же ) </p>
            <p>Возьмём ее на вооружение и проверим на практике 😜 Только в нашем случае «мир» это голова из пластилина, а остальной мир, я думаю, обязательно подключится!</p>
            <p>P.S. В день съемки этого урока я была самой веселой, активной и позитивной, чего и вам желаю пережить ;)</p>
        </div>

        <VideoView video-id="56c4b31854854849b94e320a83bb8af2"/>

        <div class="MBtextcont">
            <a href="/files/Emotions/2Smile.pdf" target="_blank" class="checklist" :class="$mq">
                <div class="checklist-img" :class="$mq">
                    <div class="checklist-icon">
                        <KImage :src="require('@/assets/miniland/check-list2.png')" alt="" width="100%"/>
                    </div>
                </div>
                <div class="checklist-text" :class="$mq">
                    <h3>Скачать чек-лист по улыбке</h3>
                    <p>Его можно распечатать, идти четко по пунткам и тем самым разобрать свою заготовку</p>
                </div>
            </a>
        </div>

    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";
    import chapters from './data.js';
    import KImage from "../../Widgets/KImage";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
            KImage,
        },
        data() {
        return {
            items: chapters
        }
        }
    }
</script>

<style>

</style>